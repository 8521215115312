/* eslint-disable no-undef, react/prop-types */
import React, { useState } from 'react'
// import Link from 'gatsby-link'
import { graphql } from 'gatsby'

import {
  Page,
  H1,
  Flex,
  Paper,
  FeatherIcon,
  Text,
  Box,
  Hr,
  Badge,
} from '../../elements'
import { ExhibitorEntry, SEO, Badges, FiltersButtons } from '../../components'
import { useFilters } from '../../utils'

const Exhibitors = ({
  data: {
    exhibitors: { nodes: exhibitors },
    filters: { nodes: filtersArray },
  },
}) => {
  // const [filtersOpen, setFiltersOpen] = useState(true)
  const {
    filterValues: { onlyFavorites, ...filterValues },
    setFilterValue,
    resetFilters,
    setFilterList,
    filters,
    filtersOpen,
    setFiltersOpen,
    filtersSelected,
  } = useFilters(
    'exhibitors',
    {
      categories: null,
      onlyFavorites: false,
    },
    filtersArray,
  )
  const setFilterCategories = ({ ...params }) => {
    setFilterList({ property: 'categories', ...params })
  }

  const color = 'exhibitors'

  return (
    <Page>
      {({ t }) => (
        <React.Fragment>
          <SEO
            {...{
              url: `/exhibitors/`,
              title: t('exhibitors'),
              // description: true,
            }}
          />
          <Paper
            {...{
              bg: 'exhibitor',
            }}
          >
            <Flex.container
              {...{
                alignItems: 'center',
                py: 0,
                css: {
                  cursor: 'pointer',
                },
                onClick: () => {
                  setFiltersOpen(!filtersOpen)
                },
              }}
            >
              <H1
                {...{
                  color: 'textAlt',
                  // px: [2, 2, 3],
                  // pl: 2,
                  css: {
                    flexGrow: 1,
                  },
                }}
              >
                {t('exhibitors')}
              </H1>
              {filtersSelected.length > 0 ? (
                <Badge
                  {...{
                    borderRadius: 3,
                    mx: 2,
                    my: '1px',
                    p: null,
                    px: 2,
                    py: '2px',
                    // mr: 1,
                    color,
                    bg: 'textAlt',
                    fontSize: 1,
                  }}
                >
                  {filtersSelected.length > 1
                    ? filtersSelected.length
                    : t(filtersSelected[0]) || '1'}
                </Badge>
              ) : (
                !onlyFavorites && (
                  <Text {...{ color: 'textAlt', m: null, mx: 2 }}>
                    {t('filters')}
                  </Text>
                )
              )}
              {onlyFavorites && (
                <Flex
                  {...{
                    width: 3,
                    flexShrink: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    bg: 'textAlt',
                    border: 2,
                    borderColor: 'textAlt',
                    mr: 2,
                    css: {
                      cursor: 'pointer',
                      borderRadius: '100%',
                    },
                  }}
                >
                  <FeatherIcon
                    {...{
                      icon: 'star',
                      stroke: color,
                      fill: 'textAlt',
                      // fill: color,
                      // size: 3,
                      size: 2,
                      // mr: 1,
                      // mb: 1,
                      css: {
                        // marginLeft: 'auto',
                        // marginRight: 'auto',
                        padding: 1,
                      },
                    }}
                  />
                </Flex>
              )}
              <FeatherIcon
                {...{
                  icon: filtersOpen ? 'arrow-up' : 'arrow-down',
                  color: 'textAlt',
                  size: 4,
                }}
              />
            </Flex.container>
            <Box.container
              {...{
                color: 'textAlt',
                py: 0,
                maxHeight: filtersOpen ? '80vh' : '0px',
                css: {
                  transition: 'max-height .7s ease-out',
                  overflow: 'hidden',
                },
              }}
            >
              <Hr {...{ border: 0, borderBottom: 1, borderColor: 'textAlt' }} />

              <Badges
                {...{
                  mb: 2,
                  list: filters.categories.map(x => {
                    const isSelected =
                      filterValues.categories && filterValues.categories[x]

                    return {
                      val: t(x),
                      color: isSelected ? color : 'altText',
                      bg: isSelected ? 'textAlt' : 'inherit',
                      toggleSelect: () => {
                        setFilterCategories({ val: x, isSelected })
                      },
                    }
                  }),
                }}
              />
              <FiltersButtons
                {...{
                  setFilterValue,
                  setFiltersOpen,
                  resetFilters,
                  color,
                  onlyFavorites,
                }}
              />
            </Box.container>
          </Paper>
          <Flex
            {...{
              flexWrap: 'wrap',
              // justifyContent: 'space-between',
              px: 0,
            }}
          >
            {exhibitors.map((e, i) => {
              if (!e) return null

              return (
                <ExhibitorEntry
                  {...{
                    key: e.id,
                    exhibitor: e,
                    filterValues,
                    onlyFavorites,
                  }}
                />
              )
            })}
          </Flex>
        </React.Fragment>
      )}
    </Page>
  )
}

export const pageQuery = graphql`
  query Exhibitors($localizedFieldName: ExhibitorFieldsEnum!) {
    exhibitors: allExhibitor(
      filter: { status: { eq: "published" } }
      sort: { fields: [$localizedFieldName] }
    ) {
      nodes {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        category
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              url
              width
              height
            }
            large {
              url
              width
              height
            }
            full {
              url
              width
              height
            }
          }
        }
      }
    }
    filters: allFilter(filter: { onType: { eq: "exhibitor" } }) {
      nodes {
        name
        values
        minValue
        maxValue
      }
    }
  }
`

export default Exhibitors
